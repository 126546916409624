<template>
	<div class="payorder">
		<el-dialog title="请扫码支付" custom-class="zhifuModai" :visible.sync="Zhifudialog" :before-close="onpayShutdown">
			<div class="zhifu">
				<div class="zhifunum">需支付<span>￥{{zhifumoney}}</span></div>
				<div class="shixiao">订单付款剩余时间：{{entermin}}分钟 {{entersec}}秒</div>
				<div class="image"><img :src="'data:image/jpg;base64,' + zhifuimg" alt=""></div>
				<div class="text"><i class="iconfont icon-weixin"></i>微信支付</div>
			</div>
		</el-dialog>
		<el-dialog title="下单" custom-class="zhifuModai" :visible.sync="Successdialog" :before-close="onSuccessGuanbi">
			<!-- 支付成功 -->
			<div class="zhifu" v-if="payfanhui">
				<div class="image"><img src="https://erlangcha.oss-cn-beijing.aliyuncs.com/indeximg/baixueying.jpg" alt=""></div>
				<div class="wenzi" v-if="orderText == '实拍'"><span class="sucee">下单成功，</span>商家已接单，如有问题请加客服微信，<br>样品寄件地址：北京市大兴区兴创国际4期208室  收件人：小二郎   电话：13601287593<br><span class="sucee">{{trichText}}</span></div>
				<div class="wenzi" v-if="orderText == '配音'"><span class="sucee">下单成功，</span>商家已接单，可加客服微信<br><span class="sucee">{{trichText}}</span></div>
			</div>
			<div class="zhifu" v-else>
				<div class="wenzi"><span class="shibai">下单失败</span>如需重新支付，请前往<span class="todingdan" @click="toRouter">订单列表</span>重新支付</div>
			</div>
		</el-dialog>
	</div>
</template>
 
<script>
export default {
	data () {
		return {
			Zhifudialog:false,//支付弹窗
			Successdialog:false,//支付成功失败弹窗
			payfanhui:true,//支付成功失败结果
			dingshi:null,//定时器
			zhifumoney:'',//支付金额
			zhifuimg:'',//支付的二维码
			order_num:'',//订单号
			tooruterpath:'',//详细信息跳转地址
			enterpath:'',//确认订单跳转地址
			orderText:'',//判断配音 、、视频

			// 倒计时
			enterday:'',
			enterhr:'',
			entermin:'',
			entersec:'',
			trichText:"",
		}
	},

	mounted(){
		this.ongetDubVerbalTrick()
	},

	methods: {
		//配音随机一条提示
		ongetDubVerbalTrick(){
			var param = {
				type:1,
			}
			this.$service.get(this.$api.getDubVerbalTrick,param, (res)=> {
				if(res.code == '200'){
					this.trichText = res.data
				}
			})
		},
		// 支付窗口关闭前回调
		onpayShutdown(){
			this.$confirm('此操作将取消支付', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$message({type: 'success',message: '已取消支付'});
				clearInterval(this.dingshi)
				this.Zhifudialog = false,//支付弹窗
				this.Successdialog = true,//支付成功失败弹窗
				this.payfanhui = false//支付成功失败结果
			})
		},
		//传递参数 图片，金额,调取弹窗
		onImgmorny(img,morny,order_num,time_expire){
			this.zhifuimg = img
			this.zhifumoney = morny
			this.order_num = order_num
			this.time_expire = time_expire
			this.Zhifudialog = true
			this.dingshi = setInterval(() => {
				if(this.orderText == '配音'){
					this.onqueryPeiyinOrderWechatPayResoult()
				}if(this.orderText == '实拍'){
					this.onqueryVideoOrder()
				}
			}, 2000);
			this.countdown()
		},
		// 查询支付结果
		onqueryVideoOrder(){
			var param = {
				order_num:this.order_num,
			}
			this.$service.post(this.$api.queryVideoOrder,param, (res)=> {
				if(res.code == '200'){
					if(res.data){
						clearInterval(this.dingshi)
						this.Zhifudialog = false//支付成功关闭二维码弹窗
						this.Successdialog = true//支付成功打开支付成功窗口
						this.payfanhui = true//传递信息支付成功	
					}
				}
			})
		},	
		// 查询支付结果
		onqueryPeiyinOrderWechatPayResoult(){
			var param = {
				order_num:this.order_num,
			}
			this.$service.post(this.$api.queryPeiyinOrderWechatPayResoult,param, (res)=> {
				if(res.code == '200'){
					if(res.data){
						clearInterval(this.dingshi)
						this.Zhifudialog = false//支付成功关闭二维码弹窗
						this.Successdialog = true//支付成功打开支付成功窗口
						this.payfanhui = true//传递信息支付成功	
					}
				}
			})
		},	
		//详细页接收跳转的地址
		togorouter(url){
			this.tooruterpath = url
		},
		//确认订单页接收跳转的地址
		enterRouter(url){
			this.enterpath = url
			// console.log(url,'跳转的页面')
		},
		//判断配音视频
		onOrdertext(text){
			this.orderText = text
			// console.log(text,'判断是配音还是实拍')
		},
		//跳转页面地址
		toRouter(){
			// console.log(this.$route)
			this.$router.push(this.tooruterpath)
		},
		//倒计时
		countdown () {
			const end = Date.parse(new Date(this.time_expire))
			const now = Date.parse(new Date())
			const msec = end - now
			if(msec<0) return;
			let day = parseInt(msec / 1000 / 60 / 60 / 24)
			let hr = parseInt(msec / 1000 / 60 / 60 % 24)
			let min = parseInt(msec / 1000 / 60 % 60)
			let sec = parseInt(msec / 1000 % 60)
			this.enterday = day
			this.enterhr = hr > 9 ? hr : '0' + hr
			this.entermin = min > 9 ? min : '0' + min
			this.entersec = sec > 9 ? sec : '0' + sec
			const that = this
			if(min>=0 && sec>=0){
				//倒计时结束关闭订单
				if(min==0 && sec==0){

				return
				}
				setTimeout(function () {
				that.countdown()
				}, 1000)
			}
		},
		// 支付成功
		onSuccessPay(){
			this.Successdialog = true
			this.payfanhui = true
		},
		//支付成功后关闭窗口跳转页面
		onSuccessGuanbi(){
			this.Successdialog = false
			this.$router.push(this.enterpath)
		},
		//拉起微信支付
		onBridgeReady(resa) {
			var that = this
			WeixinJSBridge.invoke('getBrandWCPayRequest', {
				"appId": resa.appId,     //公众号ID，由商户传入     
				"timeStamp": resa.timeStamp,     //时间戳，自1970年以来的秒数     
				"nonceStr": resa.nonceStr,      //随机串     
				"package": "prepay_id="+resa.package.prepay_id,
				"signType": resa.signType,     //微信签名方式：     
				"paySign": resa.paySign //微信签名 
			},
			function(res) {
				if (res.err_msg == "get_brand_wcpay_request:ok") {
					setTimeout(function () {
						that.Successdialog = true//支付成功打开支付成功窗口
						that.payfanhui = true//传递信息支付成功	
						that.$message({type: 'success',message: '支付成功'});
                    }, 1000);
					//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
				}if(res.err_msg == 'get_brand_wcpay_request:cancel'){
					setTimeout(function () {
						that.$message({type: 'success',message: '已取消支付'});
                    }, 1000);
				}if(res.err_msg == 'get_brand_wcpay_request:fail'){
					setTimeout(function () {
						that.$message({type: 'success',message: '支付失败'});
                    }, 1000);
				}else{
					// console.log(123)
				}
			});
			// if (typeof WeixinJSBridge == "undefined") {
			// 	if (document.addEventListener) {
			// 		document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
			// 	} else if (document.attachEvent) {
			// 		document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
			// 		document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
			// 	}
			// } else {
			// 	onBridgeReady();
			// }
		}
	}
}
</script>

<style lang="scss">
.payorder{
	.zhifuModai{
		width: 400px;
		.el-dialog__body{
			padding-top: 10px;
		}
		.zhifu{
			text-align: center;
			.image{
				width: 250px;
				height: 250px;
				margin: 0 auto;
			}
			.zhifunum{
				font-size: 24px;
				span{
					color: #f00;
					font-size: 32px;
					font-weight: 500;
				}
			}
			.text{
				color: #029a02;
				i{
					margin-right: 10px;
				}
			}
			.wenzi{
				font-size: 16px;
				margin-top: 10px;
				span{
					font-size: 16px;
				}
				.sucee{
					color: #f00;
				}
				.shibai{
					color: #ff9a56;
				}
				.todingdan{
					cursor: pointer;
					color: #2251db;
				}
			}
		}
	}
}
</style>
